.bar-chart-container {
  margin-top: 2vmin;
  max-width: 80vw;
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 2vmin;
  background-color: #121212;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative; /* Added to position the button within this container */
}

.bar-chart-container canvas {
  background-color: #121212;
  border-radius: 10px;
}

.chart-description {
  text-align: center; /* Center-align the description */
  margin-top: 2vmin;
}

.warning-message {
  display: flex;
  flex-direction: column; /* Stack warnings vertically */
  gap: 10px; /* Space between lines of warnings */
  opacity: 0; /* Initially hidden */
  transition: opacity 1s ease-in-out; /* Smooth transition */
  font-size: 1rem; /* Default font size */
  text-align: center; /* Center-align text */
}

.question-mark-button {
  position: absolute;
  top: 10px;  /* Adjust this value to align the button as desired */
  right: 10px;  /* Adjust this value to align the button as desired */
  background-color: #9692fe;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  cursor: pointer;
}

.popup {
  position: absolute;
  top: 50px;
  right: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 200px;
}

.popup p {
  color: #121212
}


/* Show warning message after 1 second */
.bar-chart-container.loaded .warning-message {
  opacity: 1; /* Make visible */
}

@media (max-width: 768px) {
  .bar-chart-container {
    max-width: 90vw; /* Adjust max-width for tablets */
  }
}

@media (max-width: 480px) {
  .bar-chart-container {
    max-width: 100vw; /* Adjust max-width for mobile devices */
    padding: 1vmin;
  }

  .popup {
    width: 150px; /* Smaller width for mobile */
    padding: 10px; /* Adjust padding for mobile */
    font-size: 14px;
  }
  .question-mark-button {
    width: 20px;
    height: 20px;
    top: -20px;
    right: 15px;
    font-size: 12px;
  }

  .warning-message {
    font-size: 0.8rem; /* Smaller font size on mobile devices */
  }
}