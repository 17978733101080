@import url("../fonts.css");
@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@500&display=swap');

.Title-container {
  display: flex;
  flex-direction: column; 
  font-family: "Rubik", sans-serif;
  justify-content: center;
  align-items: center;
  padding: 2vmin;
  font-weight: 400; /* Apply bold weight */
  margin-bottom: 5vmin;
  width: 100%;
  max-width: 70vmin;
  /* background-color: hsl(0, 0%, 98%); */
  background-color: transparent;
  border-radius: 0.8vmin;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); */
}
.Title {
  font-family: 'Merriweather Sans', sans-serif;
  font-weight: 500;
  line-height: 1.2;
  font-size: 5.5em;
  background-color: #9692fe;
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 0em;

}

.Title-container p {
  font-size: .9em;
  text-align: center; /* Center the paragraph */
  margin-top: 0; /* Ensure there's no extra space above the paragraph */
  color: #cccccc;
}

@media (max-width: 480px) {
  .Title {
    font-size: 4.5em;
  }
}

@media (max-width: 400px) {
  .Title {
    font-size: 4em;
  }
}
