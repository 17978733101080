@import url("../fonts.css");

.Option-container {
  font-family: "Supreme", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.75em;
  background-color: #1e1e1e;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 70%;
  max-width: 500px;
  height: auto;
  min-height: 150px;
  margin: 0 auto;
  margin-top: 2em;
}

.Exercise-Number {
  color: #fff;
  text-decoration: underline;
  text-decoration-color: #7469b6;
}

.workout-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
}

.Add-Workout,
.Remove-Workout {
  font-size: 1.2em;
  background-color: transparent;
  border: none;
  margin-bottom: 0.2em;
}

.Add-Workout:hover,
.Remove-Workout:hover {
  color: #7469b6;
  cursor: pointer;
}

.Workout-choice {
  margin-bottom: 1em;
  width: 100%;
}

.Workout-choice label {
  display: block;
  margin-bottom: 0.5em;
}

select,
input[type="text"] {
  width: 80%;
  padding: 0.8em;
  font-size: 1rem;
  border-radius: 4px;
  border: 1px solid #ddd;
  color: #fff;
  background-color: #2a2a2a;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.Dropdown {
  position: relative;
  width: 100%;
  display: flex;
}

.Dropdown input {
  width: 100%;
  padding: 0.8em;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.Dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #121212;
  border-radius: 4px;
  background-color: #333;
  z-index: 1000;
  padding: 0;
  margin: 0;
  list-style: none;
  color: #aaa;
}

.Dropdown-menu li {
  padding: 0.8em;
  cursor: pointer;
  border-bottom: 1px solid #121212;
}

.Dropdown-menu li:hover {
  background: #f0f0f0;
}

.Reps-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.X-divider {
  margin: 0 0.3em 0.3em 0.5em;
  font-size: 1.5rem;
  color: #fff;
}

.Analyze-Button {
  background-color: #9692fe;
  color: white;
  padding: 0.8em 1.5em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1em;
}

.Analyze-Button-Container {
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2em 0;
}
