@import url("../fonts.css");

/* Parent container to center the days-container */
.parent-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Supreme", sans-serif;
}

.days-container {
  margin-bottom: 4vmin;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  background-color: #2a2a2a; /* Changed to white for better contrast */
  border-radius: 10px; /* Rounded corners */
  padding: 1vmin; /* Added padding for better spacing */
}

/* Styling for each button */
.day-button {
  padding: 1.5vmin 3vmin;
  font-size: 1rem;
  border: transparent;
  color: #aaa;
  background-color: #2a2a2a;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease,
    box-shadow 0.3s ease;
  border-radius: 5px; /* Slightly rounded corners */
  margin: 0 0.5vmin; /* Spacing between buttons */
}

/* Styling for the selected button */
.day-button.selected {
  background-color: #9692fe; /* Highlight color */
  color: #fff;
  border-color: #9692fe;
  padding: 1.5vmin 3vmin;
}

/* Hover effect for buttons */
.day-button:hover {
  background-color: #7469b6;
  transform: scale(1.05); /* Slightly enlarges the button */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2); /* Adds shadow */
}

/* Styling for the WorkoutName input */
.WorkoutName-input {
  max-width: 30vmin;
  height: 4vmin;
  padding: 8px;
  border: none;
  border-radius: 4px;
  text-align: center;
  font-size: 2em; /* Adjust font-size as needed */
  background-color: #2a2a2a;
  color: #9692fe;
  margin-top: 2vmin; /* Space above the input */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds shadow */
  transition: box-shadow 0.3s ease;
}

/* Focus state for the WorkoutName input */
.WorkoutName-input:focus {
  outline: none; /* Removes default focus outline */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3); /* Adds shadow on focus */
  background-color: #1e1e1e; /* Darkens background on focus */
}

@media (max-width: 480px) {
  .days-container {
    width: 95%; /* Adjust this percentage to reduce width */
    max-width: 90vmin; /* Or set a maximum width in viewmin */
  }
  .day-button{
    padding: 1.5vmin 2vmin;
    margin: 0 0.3vmin; /* Reduce spacing between buttons */
    min-width: 6vmin; /* Set a minimum width for consistency */
    width: auto; /* Adjusts width based on content */
    text-align: center; 
  }
}