@import url("../fonts.css");

.waitlist-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #000000; /* Solid black background */
    color: #ffffff; /* White text color */
    font-family: 'Arial', sans-serif; /* Modern, clean font */
}

.contentWaitlist {
    text-align: center;
    padding: 2rem;
    border-radius: 8px;
    max-width: 400px; /* Control the width */
}

h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  letter-spacing: 0.05rem;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3); /* Enhanced shadow for better text visibility */
  position: relative;
  z-index: 1;

  /* Gradient and text effect */
  background-image: linear-gradient(
    -225deg,
    #ffffff 0%,
    #7d64f7 50%,
    #ffffff 100%
  );
  background-size: 200% auto;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;

  /* Animation for moving gradient */
  animation: textclip 3s linear infinite;

  /* Additional font styling */
  line-height: 1.2;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

input[type="email"] {
    padding: 0.75rem;
    margin: 1rem 0;
    border: none;
    border-radius: 4px;
    width: 100%;
    max-width: 350px; /* Increase the max width */
    font-size: 1rem;
    background-color: #333333; /* Match the theme */
    color: #ffffff; /* White text color */
}

.waitlistButton {
    padding: 0.75rem 1.5rem;
    background-color: #ffffff; /* White background */
    color: #000000; /* Black text */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

.waitlistButton:hover {
    background-color: #cccccc; /* Light gray hover */
}

p {
    margin-top: .25rem;
    color: #cccccc; /* Lighter text color */
}

.third {
    font-family: "Supreme", sans-serif;
    color: #cccccc;
    /* color: #acacac; /* Lighter text color */
}
