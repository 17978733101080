@import url("../fonts.css");
@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@100;200;300;400;500&display=swap");

body {
  margin: 0;
  font-family: "Merriweather Sans", sans-serif;
  font-weight: 400;
}

.navbar {
  position: sticky; /* Makes the navbar sticky */
  top: 0; /* Sticks the navbar to the top */
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: rgba(51, 51, 51, 0.8); /* Translucent background */
  color: #fff;
  z-index: 1000; /* Ensures the navbar is on top */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds subtle shadow */
  backdrop-filter: blur(1px); /* Adds blur effect for better readability */
}

.navbar-logo {
  width: 50px;
  height: auto;
  margin-right: 10px;
}

.navbar-title {
  font-family: "Merriweather Sans", sans-serif;
  font-weight: 500;
  font-size: 1.2rem;
  color: #fff;
  margin: 0;
  margin-right: auto;
  text-decoration: None;
}

.navbar-menu {
  display: flex;
  align-items: center;
}

.navbar-item {
  margin-left: 20px;
  font-size: 1rem;
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.navbar-links:hover {
  color: #9692fe; /* Highlight color on hover */
}

.icon-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.navbar-links {
  font-family: "Supreme", sans-serif;
  font-weight: 100;
  font-size: 1.3em;
  color: white;
  text-decoration: none;
}

/* Mobile styles */
.menu-toggle {
  display: none;
  font-size: 1.5rem;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
}

.icon-container {
  display: flex;
  gap: 20px;
}

@media (max-width: 768px) {
  .icon-container {
    display: none; /* Hide the horizontal menu */
    flex-direction: column;
    position: absolute;
    top: 60px; /* Adjust based on your navbar height */
    right: 0;
    background-color: rgba(
      51,
      51,
      51,
      0.9
    ); /* Same background color as navbar */
    width: 100%;
  }

  .icon-container.open {
    display: flex; /* Show dropdown menu when active */
  }

  .menu-toggle {
    display: block;
  }

  .navbar-links {
    font-size: 1.2em; /* Adjust font size for mobile */
    padding: 1rem;
    text-align: center;
  }
}
