@import url("./fonts.css");

.version {
  font-family: "Supreme", sans-serif;
  font-size: 1.5em;
}

.Components-Container {
  margin-top: 20vmin;
  margin-bottom: 20vmin;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  padding: 20px;
}
